import LmsSubjectIndex from '../pages/lms/subject';
import LmsSubjectIdIndex from '../pages/lms/subject/[id]';
import LmsSubjectWrite from '../pages/lms/subject/write';
import LmsBachelorIndex from '../pages/lms/bachelor';
import LmsBachelorWrite from '../pages/lms/bachelor/write';
import LmsBachelorIdIndex from '../pages/lms/bachelor/[id]';
import LmsLecturesMembersGroupsIndex from '../pages/lms/lectures/members/groups';
import LmsLecturesMembersGroupsId from '../pages/lms/lectures/members/groups/[id]';
import LmsLecturesMembersMembersIndex from '../pages/lms/lectures/members/members';
import LmsMessagesContactsIndex from '../pages/lms/messages/contacts';
import LmsMessagesContactsIdEditIndex from '../pages/lms/messages/contacts/[id]';
import LmsMessagesNoticesIndex from '../pages/lms/messages/notices';
import LmsMessagesNoticesIdIndex from '../pages/lms/messages/notices/[id]';
import LmsMessagesNoticesWrite from '../pages/lms/messages/notices/write';
import LmsMessagesPdsWrite from '../pages/lms/messages/pds/write';
import LmsMessagesPds from '../pages/lms/messages/pds';
import LmsMessagesIdPds from '../pages/lms/messages/pds/[id]';
import LmsNotesIndex from '../pages/lms/notes/[type]';
import LmsVotesIndex from '../pages/lms/votes';
import LmsVotesWrite from '../pages/lms/votes/write';
import LmsVotesIdIndex from '../pages/lms/votes/[id]';
import LmsContentsIndex from '../pages/lms/contents';
import LecturesVideosIndex from '../pages/lms/lectures/video';
import LecturesOfflinesIndex from '../pages/lms/lectures/offline';
import LecturesQnasIndex from '../pages/lms/lectures/qnas';
import LecturesAttendancesIndex from '../pages/lms/lectures/attendance';
import LecturesAttendancesVideo from '../pages/lms/lectures/attendance/video';
import LecturesAttendancesOffline from '../pages/lms/lectures/attendance/offline';
import LecturesAttendancesQna from '../pages/lms/lectures/attendance/qna';
import LecturesAttendancesCheck from '../pages/lms/lectures/attendance/check';
import LecturesDurationIndex from '../pages/lms/lectures/duration/';
import LmsHomeworksIndex from '../pages/lms/homeworks';
import LmsHomeworksIdIndex from '../pages/lms/homeworks/[id]';
import LmsHomeworksIdDetails from '../pages/lms/homeworks/[id]/details';
import LmsHomeworkDetails from '../pages/lms/homeworks/details';
import LecturesOfflinesForm from '../pages/lms/lectures/offline/form';
import LecturesQnasForm from '../pages/lms/lectures/qnas/form';
import LecturesOfflinesDetails from '../pages/lms/lectures/offline/details';
import LmsHomeworkForm from '../pages/lms/homeworks/form';
import LmsExamsItemsIndex from '../pages/lms/exams/items';
import LmsExamsItemsWrite from '../pages/lms/exams/items/write';
import LmsExamsItemsIdDetails from '../pages/lms/exams/items/[id]';
import LmsTalksMessagesIndex from '../pages/lms/talks/messages';
import LmsTalksMessagesWrite from '../pages/lms/talks/messages/write';
import LmsTalksMailWrite from '../pages/lms/talks/mail/write';
import LmsMasterIndex from '../pages/lms/master';
import LmsMasterIdIndex from '../pages/lms/master/[id]';
import LmsMasterWrite from '../pages/lms/master/write';
import LmsAccountsIndex from '../pages/lms/accounts';
import LmsAccountsIdIndex from '../pages/lms/accounts/[id]';
import LmsAccountsWrite from '../pages/lms/accounts/write';
import SiteScheduleIndex from '../pages/site/schedule';
import SiteScheduleIdIndex from '../pages/site/schedule/[id]';
import SiteScheduleWrite from '../pages/site/schedule/write';
import SiteLogIndex from '../pages/site/log';
import SiteInfoPolicyIndex from '../pages/site/infoPolicy';
import SiteInfoPolicyIdIndex from '../pages/site/infoPolicy/[id]';
import SiteInfoPolicyWrite from '../pages/site/infoPolicy/write';
import SiteSiteManagerMainVisualIndex from '../pages/site/siteManager/mainVisual';
import SiteSiteManagerMainVisualIdIndex from '../pages/site/siteManager/mainVisual/[id]';
import SiteSiteManagerMainVisualWrite from '../pages/site/siteManager/mainVisual/write';
import SiteSiteManagerPopUpIndex from '../pages/site/siteManager/popup';
import SiteSiteManagerPopUpIdIndex from '../pages/site/siteManager/popup/[id]';
import SiteSiteManagerPopUpWrite from '../pages/site/siteManager/popup/write';
import SiteBoardImbaVideoIndex from '../pages/site/board/imbaVideo';
import SiteBoardImbaVideoIdIndex from '../pages/site/board/imbaVideo/[id]';
import SiteBoardBizIndex from '../pages/site/board/biz';
import SiteBoardBizWrite from '../pages/site/board/biz/write';
import SiteBoardBizIdIndex from '../pages/site/board/biz/[id]';
import SiteBoardBizIdView from '../pages/site/board/biz/[id]/view';
import SiteBoardInterviewIndex from '../pages/site/board/interview';
import SiteBoardInterviewIdIndex from '../pages/site/board/interview/[id]';
import SiteBoardInterviewWrite from '../pages/site/board/interview/write';
import SiteBoardApplicationIndex from '../pages/site/board/application';
import SiteBoardApplicationWrite from '../pages/site/board/application/write';
import SiteBoardApplicationIdIndex from '../pages/site/board/application/[id]';
import SiteBoardFapIndex from '../pages/site/board/faq';
import SiteBoardFapIdIndex from '../pages/site/board/faq/[id]';
import SiteBoardFapWrite from '../pages/site/board/faq/write';
import SiteBoardEntranceQnaIndex from '../pages/site/board/entranceQna';
import SiteBoardEntranceQnaIdIndex from '../pages/site/board/entranceQna/[id]';
import SiteBoardSknuStoryIndex from '../pages/site/board/sknuStory';
import SiteBoardSknuStoryIdIndex from '../pages/site/board/sknuStory/[id]';
import SiteBoardSknuStoryWrite from '../pages/site/board/sknuStory/write';
import SiteBoardImbaNewsIndex from '../pages/site/board/imbaNews';
import SiteBoardImbaNewsIdIndex from '../pages/site/board/imbaNews/[id]';
import SiteBoardImbaNewsWrite from '../pages/site/board/imbaNews/write';
import SiteBoardClubIndex from '../pages/site/board/club';
import SiteBoardClubWrite from '../pages/site/board/club/write';
import SiteBoardClubIdIndex from '../pages/site/board/club/[id]';
import SiteBoardResearchIndex from '../pages/site/board/research';
import SiteBoardResearchWrite from '../pages/site/board/research/write';
import SiteBoardResearchIdIndex from '../pages/site/board/research/[id]';
import SiteBoardNoticeIndex from '../pages/site/board/notice';
import SiteBoardNoticeIdIndex from '../pages/site/board/notice/[id]';
import SiteBoardNoticeWrite from '../pages/site/board/notice/write';
import SiteBoardOurStoryIndex from '../pages/site/board/ourStory';
import SiteBoardOurStoryIdIndex from '../pages/site/board/ourStory/[id]';
import SiteBoardOurStoryWrite from '../pages/site/board/ourStory/write';
import SiteBoardStuQnaIndex from '../pages/site/board/stuQna';
import SiteBoardStuQnaIdIndex from '../pages/site/board/stuQna/[id]';
import SiteBoardStuQnaWrite from '../pages/site/board/stuQna/write';
import SiteBoardSpecialIndex from '../pages/site/board/special';
import SiteBoardSpecialIdIndex from '../pages/site/board/special/[id]';
import SiteBoardSpecialWrite from '../pages/site/board/special/write';
import SiteBoardDataLibraryIndex from '../pages/site/board/dataLibrary';
import SiteBoardDataLibraryIdIndex from '../pages/site/board/dataLibrary/[id]';
import SiteBoardDataLibraryWrite from '../pages/site/board/dataLibrary/write';
import SiteProfessorManagerProfessorIndex from '../pages/site/professorManager/professor';
import SiteProfessorManagerProfessorIdIndex from '../pages/site/professorManager/professor/[id]';
import SiteProfessorManagerProfessorWrite from '../pages/site/professorManager/professor/write';
import SiteProfessorManagerSubjectIndex from '../pages/site/professorManager/subject';
import SiteProfessorManagerSubjectIdIndex from '../pages/site/professorManager/subject/[id]';
import SiteProfessorManagerSubjectWrite from '../pages/site/professorManager/subject/write';
import LecturesQnasDetails from '../pages/lms/lectures/qnas/details';
import LmsExamsItemsIdDetailsPaper from '../pages/lms/exams/items/[id]/paper';
import LmsExamsItemsIdDetailsUsers from '../pages/lms/exams/items/[id]/users';
import ProfileIndex from '../pages/profile/index';
import ProfileIdEdit from '../pages/profile/edit';
import LmsLecturesMembersGroupsCreate from '../pages/lms/lectures/members/groups/create';
import LmsTalksMailIndex from '../pages/lms/talks/mail';
import SiteCalendarIndex from '../pages/site/board/calendar';
import SiteCalendarWrite from '../pages/site/board/calendar/write';
import SiteCalendarIdIndex from '../pages/site/board/calendar/[id]';
import LmsRecodeIndex from '../pages/lms/record';
import LmsRecodeWrite from '../pages/lms/record/write';
import LecturesStudentManageIndex from '../pages/lms/lectures/studentManage';
import LmsAccountsPermissionIndex from '../pages/lms/accounts/permission';
import LmsExamsItemsRetry from '../pages/lms/exams/items/[id]/retry';
import MultiCampusDataIndex from '../pages/lms/lectures/multicampusData'

const configs = process.env || {};
export const getConfigValue = (key, def) => {
  if (configs?.[`REACT_APP_${key}`]) return configs?.[`REACT_APP_${key}`];
  return def;
};

export const CONTENT_TYPES = {
  VIDEO: '동영상 강의',
  OFFLINE: '오프라인 강의',
  QNA: '화상Q&A',
  EBIZ: 'E비즈 인사이트',
  ETC: '기타',
};

const isLocal = getConfigValue('API_HOST').includes('localhost');
const isDEV = !getConfigValue('API_HOST').includes('skku-imba.net');
const privacy = !isLocal;

export const MENU_ITEMS = [
  {
    label: 'LMS',
    items: [
      {
        pcode: 'main_class',
        menu: '과목 관리',
        name: '과목 관리 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/subject',
        component: LmsSubjectIndex,
        items: [
          {
            name: '과목 관리 - 상세',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/subject/:id',
            component: LmsSubjectIdIndex,
          },
          {
            name: '과목 관리 - 수정',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/subject/view/:id/edit',
            component: LmsSubjectWrite,
          },
          {
            name: '과목 관리 - 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/subject/write',
            component: LmsSubjectWrite,
          },
        ],
      },
      {
        pcode: 'lecture',
        menu: '학사 관리',
        name: '학사 관리 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/bachelor',
        component: LmsBachelorIndex,
        items: [
          {
            name: '학사 관리 - 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/bachelor/write',
            component: LmsBachelorWrite,
          },
          {
            name: '학사 관리 - 상세',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/bachelor/:id',
            component: LmsBachelorIdIndex,
          },
          {
            name: '학사 관리 - 수정',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/bachelor/view/:id/edit',
            component: LmsBachelorWrite,
          },
        ],
      },
      {
        menu: '강의관리',
        name: '강의관리 - 목록',
        roles: ['ADMIN', 'MANAGER', 'CP'],
        items: [
          {
            pcode: 'lecture_video',
            menu: '동영상 강의',
            name: '동영상 강의 - 목록',
            roles: ['ADMIN', 'MANAGER', 'CP'],
            path: '/lms/lectures/video',
            component: LecturesVideosIndex,
          },
          {
            pcode: 'lecture_offline',
            menu: '오프라인 강의',
            name: '오프라인 강의 - 목록',
            roles: ['ADMIN', 'MANAGER', 'CP'],
            path: '/lms/lectures/offline',
            component: LecturesOfflinesIndex,
            items: [
              {
                name: '오프라인 강의 - 등록',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
                path: '/lms/lectures/offline/create',
                component: LecturesOfflinesForm,
              },
              {
                name: '오프라인 강의 - 상세',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
                path: '/lms/lectures/offline/view/:id',
                component: LecturesOfflinesDetails,
              },
              {
                name: '오프라인 강의 - 수정',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
                path: '/lms/lectures/offline/view/:id/edit',
                component: LecturesOfflinesForm,
              },
            ],
          },
          {
            pcode: 'lecture_qna',
            menu: '화상 Q&A',
            name: '화상 Q&A - 목록',
            roles: ['ADMIN', 'MANAGER', 'CP'],
            path: '/lms/lectures/qnas',
            component: LecturesQnasIndex,
            items: [
              {
                name: '화상 Q&A - 등록',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
                path: '/lms/lectures/qnas/create',
                component: LecturesQnasForm,
              },
              {
                name: '화상 Q&A - 상세',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
                path: '/lms/lectures/qnas/view/:id',
                component: LecturesQnasDetails,
              },
              {
                name: '화상 Q&A - 수정',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
                path: '/lms/lectures/qnas/view/:id/edit',
                component: LecturesQnasForm,
              },
            ],
          },
        ],
      },

      {
        pcode: 'contents',
        menu: '컨텐츠 관리',
        name: '컨텐츠 관리 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'CP'],
        path: '/lms/contents',
        component: LmsContentsIndex,
      },

      {
        pcode: 'lecture_notice',
        menu: '공지사항',
        name: '공지사항 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
        path: '/lms/messages/notices',
        component: LmsMessagesNoticesIndex,
        items: [
          {
            name: '공지사항 - 등록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/messages/notices/write',
            component: LmsMessagesNoticesWrite,
          },
          {
            name: '공지사항 - 상세',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/messages/notices/:id',
            component: LmsMessagesNoticesIdIndex,
          },
          {
            name: '공지사항 - 수정',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/messages/notices/:id/edit',
            component: LmsMessagesNoticesWrite,
          },
        ],
      },

      {
        pcode: 'lecture_contacts',
        menu: '문의게시판',
        name: '문의게시판 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
        path: '/lms/messages/contacts',
        component: LmsMessagesContactsIndex,
        items: [
          {
            name: '문의게시판 - 상세',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/messages/contacts/:id',
            component: LmsMessagesContactsIdEditIndex,
          },
        ],
      },

      {
        pcode: 'lecture_video',
        menu: '동영상 강의',
        name: '동영상 강의 - 목록',
        roles: ['TEACHER', 'STAFF'],
        path: '/lms/lectures/video',
        component: LecturesVideosIndex,
      },
      {
        pcode: 'lecture_offline',
        menu: '오프라인 강의',
        name: '오프라인 강의 - 목록',
        roles: ['TEACHER', 'STAFF'],
        path: '/lms/lectures/offline',
        component: LecturesOfflinesIndex,
        items: [
          {
            name: '오프라인 강의 - 등록',
            roles: ['TEACHER', 'STAFF'],
            path: '/lms/lectures/offline/create',
            component: LecturesOfflinesForm,
          },
          {
            name: '오프라인 강의 - 상세',
            roles: ['TEACHER', 'STAFF'],
            path: '/lms/lectures/offline/view/:id',
            component: LecturesOfflinesDetails,
          },
          {
            name: '오프라인 강의 - 수정',
            roles: ['TEACHER', 'STAFF'],
            path: '/lms/lectures/offline/view/:id/edit',
            component: LecturesOfflinesForm,
          },
        ],
      },
      {
        pcode: 'lecture_qna',
        menu: '화상 Q&A',
        name: '화상 Q&A - 목록',
        roles: ['TEACHER', 'STAFF'],
        path: '/lms/lectures/qnas',
        component: LecturesQnasIndex,
        items: [
          {
            name: '화상 Q&A - 등록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/qnas/create',
            component: LecturesQnasForm,
          },
          {
            name: '화상 Q&A - 상세',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/qnas/view/:id',
            component: LecturesQnasDetails,
          },
          {
            name: '화상 Q&A - 수정',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/qnas/view/:id/edit',
            component: LecturesQnasForm,
          },
        ],
      },

      {
        pcode: 'homework',
        menu: '과제관리',
        name: '과제관리',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
        items: [
          {
            menu: '개인과제',
            name: '개인과제',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/homeworks/single',
            component: LmsHomeworksIndex,
          },
          {
            menu: '그룹과제',
            name: '그룹과제',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/homeworks/group',
            component: LmsHomeworksIndex,
          },
          {
            name: '개인과제',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/homeworks/:type',
            component: LmsHomeworksIndex,
            items: [
              {
                name: '개인과제 - 목록',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
                path: '/lms/homeworks/:type/:id',
                component: LmsHomeworksIdIndex,
              },
              {
                name: '개인과제 - 상세',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
                path: '/lms/homeworks/:type/:id/detail',
                component: LmsHomeworksIdDetails,
              },
              {
                name: '개인과제 - 수정',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
                path: '/lms/homeworks/:type/:id/edit',
                component: LmsHomeworkForm,
              },
              {
                name: '과제관리 - 상세',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
                path: '/lms/homeworks/:type/:id',
                component: LmsHomeworkDetails,
              },
              {
                name: '과제관리 - 상세',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
                path: '/lms/homeworks/:type/create',
                component: LmsHomeworkForm,
              },
            ],
          },
        ],
      },

      {
        pcode: 'exam',
        menu: '시험관리',
        // ready: process.env.NODE_ENV !== 'development',
        name: '시험관리 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
        path: '/lms/exams/items',
        component: LmsExamsItemsIndex,
        items: [
          {
            name: '시험관리 - 등록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/exams/items/write',
            component: LmsExamsItemsWrite,
          },
          {
            name: '시험관리 - 상세',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/exams/items/:id',
            component: LmsExamsItemsIdDetails,
          },
          {
            name: '시험관리 - 상세 - 시험지 보기',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/exams/items/:id/paper',
            component: LmsExamsItemsIdDetailsPaper,
          },
          {
            name: '시험관리 - 상세 - 응시자 보기',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/exams/items/:id/users',
            component: LmsExamsItemsIdDetailsUsers,
          },

          {
            name: '시험관리 - 수정',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/exams/items/:id/edit',
            component: LmsExamsItemsWrite,
          },

          {
            name: '시험관리 - 재응시',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER','EXAMSTAFF'],
            path: '/lms/exams/items/:id/retry',
            component: LmsExamsItemsRetry,
          },

          //
        ],
      },

      {
        pcode: 'lecture_pds',
        menu: '자료실',
        name: '자료실 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
        path: '/lms/messages/pds',
        component: LmsMessagesPds,
        items: [
          {
            name: '자료실 - 상세',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/messages/pds/:id',
            component: LmsMessagesIdPds,
          },
          {
            name: '자료실 - 등록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/messages/pds/write',
            component: LmsMessagesPdsWrite,
          },
          {
            name: '자료실 - 수정',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/messages/pds/:id/edit',
            component: LmsMessagesPdsWrite,
          },
        ],
      },

      {
        menu: '멤버',
        name: '멤버 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
        items: [
          {
            pcode: 'lecture_account_users',
            menu: '개인관리',
            privacy,
            name: '개인관리 - 목록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/members/members',
            component: LmsLecturesMembersMembersIndex,
          },
          {
            pcode: 'lecture_account_teams',
            menu: '그룹관리',
            privacy,
            name: '그룹관리 - 목록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/members/groups',
            component: LmsLecturesMembersGroupsIndex,
            items: [
              {
                name: '그룹관리 - 등록',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
                path: '/lms/lectures/members/groups/create',
                component: LmsLecturesMembersGroupsCreate,
              },
              {
                name: '그룹관리 - 상세',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
                path: '/lms/lectures/members/groups/:id',
                component: LmsLecturesMembersGroupsId,
              },
              {
                name: '그룹관리 - 수정',
                roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
                path: '/lms/lectures/members/groups/:id/edit',
                component: LmsLecturesMembersGroupsCreate,
              },
            ],
          },
        ],
      },

      {
        pcode: 'lecture_attandance',
        menu: '출석관리',
        name: '출석관리 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
        items: [
          {
            menu: '전체',
            name: '출석관리 - 전체목록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/attendance',
            component: LecturesAttendancesIndex,
          },
          {
            menu: '동영상 강의',
            name: '동영상 강의 - 목록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/attendance/video',
            component: LecturesAttendancesVideo,
          },
          {
            menu: '오프라인 강의',
            name: '오프라인 강의 - 목록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/attendance/offline',
            component: LecturesAttendancesOffline,
          },
          {
            menu: '화상 Q&A',
            name: '화상 Q&A - 목록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/attendance/qna',
            component: LecturesAttendancesQna,
          },
          {
            name: '출석관리',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/lectures/attendance/:type/:id',
            component: LecturesAttendancesCheck,
          },
        ],
      },

      {
        pcode: 'vote',
        menu: '설문',
        name: '설문 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
        path: '/lms/votes',
        component: LmsVotesIndex,
        items: [
          {
            name: '설문 - 등록',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/votes/write',
            component: LmsVotesWrite,
          },
          {
            name: '설문 - 상세',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/votes/:id',
            component: LmsVotesIdIndex,
          },
          {
            name: '설문 - 수정',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/votes/:id/edit',
            component: LmsVotesWrite,
          },
        ],
      },

      {
        menu: '쪽지함',
        name: '쪽지함 - 목록',
        roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
        path: '/lms/notes/receive',
        items: [
          {
            name: '쪽지함',
            roles: ['TEACHER', 'STAFF', 'ADMIN', 'MANAGER'],
            path: '/lms/notes/:type',
            component: LmsNotesIndex,
          },
        ],
      },

      {
        pcode: 'users',
        menu: '원우관리',
        privacy,
        name: '원우관리 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/lectures/studentManage',
        component: LecturesStudentManageIndex,
      },
      
      {
        pcode: 'lecture_progress',
        menu: '학습현황관리',
        privacy,
        name: '학습현황관리',
        roles: ['ADMIN', 'MANAGER', 'TEACHER', 'STAFF'],
        // ready: !isLocal && !isDEV,
        path: '/lms/lectures/duration',
        component: LecturesDurationIndex,
      },

      {
        pcode: 'message',
        menu: '메세지 보내기',
        privacy,
        name: '메세지 보내기 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/talks/messages',
        component: LmsTalksMessagesIndex,
        items: [
          {
            name: '메세지 보내기 - 메일 상세',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/talks/mail',
            component: LmsTalksMailIndex,
          },
          {
            name: '메세지 보내기 - 문자 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/talks/messages/write',
            component: LmsTalksMessagesWrite,
          },
          {
            name: '메세지 보내기 - 메일 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/talks/mail/write',
            component: LmsTalksMailWrite,
          },
        ],
      },

      {
        pcode: 'lecture_account',
        menu: '수강이력 관리',
        ready: !isDEV,
        privacy,
        name: '수강이력 관리 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/record',
        component: LmsRecodeIndex,
        items: [
          {
            name: '수강이력 관리 - 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/record/write',
            component: LmsRecodeWrite,
          },
        ],
      },

      {
        pcode: 'semester',
        menu: '마스터값 설정',
        name: '마스터값 설정 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/master',
        component: LmsMasterIndex,
        items: [
          {
            name: '마스터값 설정 - 수정',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/master/:id',
            component: LmsMasterIdIndex,
          },
          {
            name: '마스터값 설정 - 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/master/write',
            component: LmsMasterWrite,
          },
          {
            name: '마스터값 설정 - 수정',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/master/:id/edit',
            component: LmsMasterWrite,
          },
        ],
      },

      {
        pcode: 'account',
        menu: '계정/권한관리',
        privacy,
        // ready: process.env.NODE_ENV !== 'development',
        name: '계정/권한관리 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/accounts',
        component: LmsAccountsIndex,
        items: [
          {
            name: '권한설정',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/accounts/permission',
            component: LmsAccountsPermissionIndex,
          },
          {
            name: '계정관리 - 상세',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/accounts/:id',
            component: LmsAccountsIdIndex,
          },
          {
            name: '계정관리 - 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/accounts/write',
            component: LmsAccountsWrite,
          },
          {
            name: '계정관리 - 수정',
            roles: ['ADMIN', 'MANAGER'],
            path: '/lms/accounts/:id/edit',
            component: LmsAccountsWrite,
          },
        ],
      },
    ],
  },
  {
    label: 'SITE MANAGER',
    items: [
      {
        menu: '비주얼 관리',
        name: '비주얼 관리 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        items: [
          {
            pcode: 'site_visual',
            menu: '메인 비주얼 관리',
            name: '메인 비주얼 관리 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/siteManager/mainVisual',
            component: SiteSiteManagerMainVisualIndex,
            items: [
              {
                name: '메인 비주얼 관리 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/siteManager/mainVisual/:id',
                component: SiteSiteManagerMainVisualIdIndex,
              },
              {
                name: '메인 비주얼 관리 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/siteManager/mainVisual/write',
                component: SiteSiteManagerMainVisualWrite,
              },
              {
                name: '메인 비주얼 관리 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/siteManager/mainVisual/:id/edit',
                component: SiteSiteManagerMainVisualWrite,
              },
            ],
          },
          {
            pcode: 'site_popup',
            menu: '팝업관리',
            name: '팝업관리 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/siteManager/popup',
            component: SiteSiteManagerPopUpIndex,
            items: [
              {
                name: '팝업관리 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/siteManager/popup/:id',
                component: SiteSiteManagerPopUpIdIndex,
              },
              {
                name: '팝업관리 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/siteManager/popup/write',
                component: SiteSiteManagerPopUpWrite,
              },
              {
                name: '팝업관리 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/siteManager/popup/:id/edit',
                component: SiteSiteManagerPopUpWrite,
              },
            ],
          },
        ],
      },

      {
        menu: '대학원소개',
        name: '대학원 소개 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        items: [
          {
            pcode: 'site_video',
            menu: 'IMBA 홍보동영상',
            name: 'IMBA 홍보동영상',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/imbaVideo',
            component: SiteBoardImbaVideoIndex,
            items: [
              {
                name: 'IMBA 홍보동영상 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/imbaVideo/:id',
                component: SiteBoardImbaVideoIdIndex,
              },
            ],
          },
          {
            pcode: 'site_notice',
            menu: '공지사항',
            name: '공지사항',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/notice',
            component: SiteBoardNoticeIndex,
            items: [
              {
                name: '공지사항 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/notice/:id',
                component: SiteBoardNoticeIdIndex,
              },
              {
                name: '공지사항 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/notice/write',
                component: SiteBoardNoticeWrite,
              },
              {
                name: '공지사항 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/notice/:id/edit',
                component: SiteBoardNoticeWrite,
              },
            ],
          },
        ],
      },

      {
        pcode: 'teacher',
        menu: '교수진',
        name: '교수진 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        items: [
          {
            menu: '교수진 관리',
            name: '교수진 관리 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/professorManager/professor',
            component: SiteProfessorManagerProfessorIndex,
            items: [
              {
                name: '교수진 관리 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/professorManager/professor/:id',
                component: SiteProfessorManagerProfessorIdIndex,
              },
              {
                name: '교수진 관리 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/professorManager/professor/write',
                component: SiteProfessorManagerProfessorWrite,
              },
              {
                name: '교수진 관리 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/professorManager/professor/:id/edit',
                component: SiteProfessorManagerProfessorWrite,
              },
            ],
          },
        ],
      },

      {
        menu: '입학',
        name: '입학 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        items: [
          {
            pcode: 'enter_admission',
            menu: '모집요강',
            name: '모집요강 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/application',
            component: SiteBoardApplicationIndex,
            items: [
              {
                name: '모집요강 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/application/write',
                component: SiteBoardApplicationWrite,
              },
              {
                name: '모집요강 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/application/:id',
                component: SiteBoardApplicationIdIndex,
              },
              {
                name: '모집요강 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/application/:id/edit',
                component: SiteBoardApplicationWrite,
              },
            ],
          },
          {
            pcode: 'enter_interview',
            menu: '면접안내',
            name: '면접안내 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/interview',
            component: SiteBoardInterviewIndex,
            items: [
              {
                name: '면접안내 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/interview/:id',
                component: SiteBoardInterviewIdIndex,
              },
              {
                name: '면접안내 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/interview/write',
                component: SiteBoardInterviewWrite,
              },
              {
                name: '면접안내 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/interview/:id/edit',
                component: SiteBoardInterviewWrite,
              },
            ],
          },

          {
            pcode: 'enter_faq',
            menu: '입학 FAQ',
            name: '입학 FAQ - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/faq',
            component: SiteBoardFapIndex,
            items: [
              {
                name: '입학 FAQ - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/faq/:id',
                component: SiteBoardFapIdIndex,
              },
              {
                name: '입학 FAQ - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/faq/write',
                component: SiteBoardFapWrite,
              },
              {
                name: '입학 FAQ - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/faq/:id/edit',
                component: SiteBoardFapWrite,
              },
            ],
          },

          {
            pcode: 'enter_qna',
            menu: '입학 Q&A',
            name: '입학 Q&A - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/entranceQna',
            component: SiteBoardEntranceQnaIndex,
            items: [
              {
                name: '입학 Q&A - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/entranceQna/:id',
                component: SiteBoardEntranceQnaIdIndex,
              },
            ],
          },
        ],
      },
      {
        menu: '교육과정',
        name: '교육과정 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        items: [
          {
            pcode: 'learn_subject',
            menu: '교과목 소개 등록',
            name: '교과목 소개 등록 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/professorManager/subject',
            component: SiteProfessorManagerSubjectIndex,
            items: [
              {
                name: '교과목 소개 등록 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/professorManager/subject/:id',
                component: SiteProfessorManagerSubjectIdIndex,
              },
              {
                name: '교과목 소개 등록 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/professorManager/subject/Write',
                component: SiteProfessorManagerSubjectWrite,
              },
              {
                name: '교과목 소개 등록 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/professorManager/subject/:id/edit',
                component: SiteProfessorManagerSubjectWrite,
              },
            ],
          },
          {
            pcode: 'learn_calendar',
            menu: '학사일정',
            name: '학사일정 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/schedule',
            component: SiteScheduleIndex,
            items: [
              {
                name: '학사일정 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/schedule/:id',
                component: SiteScheduleIdIndex,
              },
              {
                name: '학사일정 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/schedule/write',
                component: SiteScheduleWrite,
              },
              {
                name: '학사일정 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/schedule/:id/edit',
                component: SiteScheduleWrite,
              },
            ],
          },
        ],
      },

      {
        menu: '커뮤니티',
        name: '커뮤니티 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        items: [
          {
            pcode: 'comm_story',
            menu: '원우이야기',
            name: '원우이야기 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/sknuStory',
            component: SiteBoardSknuStoryIndex,
            items: [
              {
                name: '원우이야기 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/sknuStory/:id',
                component: SiteBoardSknuStoryIdIndex,
              },
              {
                name: '원우이야기 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/sknuStory/write',
                component: SiteBoardSknuStoryWrite,
              },
              {
                name: '원우이야기 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/sknuStory/:id/edit',
                component: SiteBoardSknuStoryWrite,
              },
            ],
          },
          {
            pcode: 'comm_news',
            menu: 'IMBA 소식',
            name: 'IMBA 소식 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/imbaNews',
            component: SiteBoardImbaNewsIndex,
            items: [
              {
                name: 'IMBA 소식 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/imbaNews/:id',
                component: SiteBoardImbaNewsIdIndex,
              },
              {
                name: 'IMBA 소식 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/imbaNews/write',
                component: SiteBoardImbaNewsWrite,
              },
              {
                name: 'IMBA 소식 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/imbaNews/:id/edit',
                component: SiteBoardImbaNewsWrite,
              },
            ],
          },
          {
            pcode: 'comm_calendar',
            menu: 'IMBA Calendar',
            name: 'IMBA Calendar - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/calendar',
            component: SiteCalendarIndex,
            items: [
              {
                name: 'IMBA Calendar - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/calendar/write',
                component: SiteCalendarWrite,
              },
              {
                name: 'IMBA Calendar - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/calendar/:id/edit',
                component: SiteCalendarWrite,
              },
              {
                name: 'IMBA Calendar - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/calendar/:id',
                component: SiteCalendarIdIndex,
              },
            ],
          },
          {
            pcode: 'comm_club',
            menu: '동호회',
            name: '동호회 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/club',
            component: SiteBoardClubIndex,
            items: [
              {
                name: '동호회 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/club/write',
                component: SiteBoardClubWrite,
              },
              {
                name: '동호회 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/club/:id',
                component: SiteBoardClubIdIndex,
              },
              {
                name: '동호회 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/club/:id/edit',
                component: SiteBoardClubWrite,
              },
            ],
          },

          {
            pcode: 'comm_research',
            menu: '연구회',
            name: '연구회 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/research',
            component: SiteBoardResearchIndex,
            items: [
              {
                name: '연구회 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/research/write',
                component: SiteBoardResearchWrite,
              },
              {
                name: '연구회 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/research/:id',
                component: SiteBoardResearchIdIndex,
              },
              {
                name: '연구회 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/research/:id/edit',
                component: SiteBoardResearchWrite,
              },
            ],
          },

          {
            pcode: 'comm_free',
            menu: '자유게시판',
            name: '자유게시판 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/ourStory',
            items: [
              {
                menu: '우리들 이야기',
                name: '우리들 이야기 - 목록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/ourStory',
                component: SiteBoardOurStoryIndex,
              },
              {
                name: '우리들 이야기 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/ourStory/:id',
                component: SiteBoardOurStoryIdIndex,
              },
              {
                name: '우리들 이야기 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/ourStory/write',
                component: SiteBoardOurStoryWrite,
              },
              {
                name: '우리들 이야기 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/ourStory/:id/edit',
                component: SiteBoardOurStoryWrite,
              },
              {
                menu: '재학생 Q&A',
                name: '재학생 Q&A - 목록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/stuQna',
                component: SiteBoardStuQnaIndex,
              },
              {
                name: '재학생 Q&A - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/stuQna/:id',
                component: SiteBoardStuQnaIdIndex,
              },
              {
                name: '재학생 Q&A - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/stuQna/write',
                component: SiteBoardStuQnaWrite,
              },
              {
                menu: '명사특강',
                name: '명사특강 - 목록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/special',
                component: SiteBoardSpecialIndex,
              },
              {
                name: '명사특강 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/special/:id',
                component: SiteBoardSpecialIdIndex,
              },
              {
                name: '명사특강 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/special/write',
                component: SiteBoardSpecialWrite,
              },
              {
                name: '명사특강 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/special/:id/edit',
                component: SiteBoardSpecialWrite,
              },
              {
                menu: '강의/학습자료실',
                name: '강의/학습자료실 - 목록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/dataLibrary',
                component: SiteBoardDataLibraryIndex,
              },
              {
                name: '강의/학습자료실 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/dataLibrary/:id',
                component: SiteBoardDataLibraryIdIndex,
              },
              {
                name: '강의/학습자료실 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/dataLibrary/write',
                component: SiteBoardDataLibraryWrite,
              },
              {
                name: '강의/학습자료실 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/dataLibrary/:id/edit',
                component: SiteBoardDataLibraryWrite,
              },
            ],
          },
          {
            pcode: 'comm_biz',
            menu: 'Biz 인사이트 관리',
            name: 'Biz 인사이트 관리 - 목록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/board/biz',
            component: SiteBoardBizIndex,
            items: [
              {
                name: 'Biz 인사이트 관리 - 등록',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/biz/write',
                component: SiteBoardBizWrite,
              },
              {
                name: 'Biz 인사이트 관리 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/biz/:id',
                component: SiteBoardBizIdIndex,
              },
              {
                name: 'Biz 인사이트 관리 - 상세',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/biz/:id/view',
                component: SiteBoardBizIdView,
              },
              {
                name: 'Biz 인사이트 관리 - 수정',
                roles: ['ADMIN', 'MANAGER'],
                path: '/site/board/biz/:id/edit',
                component: SiteBoardBizWrite,
              },
            ],
          },
        ],
      },

      {
        menu: '기존이력확인',
        privacy,
        name: '기존이력확인 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/lms/lectures/multicampusData',
        component: MultiCampusDataIndex,
      },

      // {
      //   menu: '원우정보',
      //   name: '원우정보 - 목록',
      //   roles: ['ADMIN','MANAGER'],
      //   path: '/site/studentInfo',
      //   component: SiteStudentInfoIndex,
      // },
      // {
      //   roles: ['ADMIN','MANAGER'],
      //   path: '/site/studentInfo/:id',
      //   component: SiteStudentInfoIdIndex,
      // },
      // {
      //   roles: ['ADMIN','MANAGER'],
      //   path: '/site/studentInfo/write',
      //   component: SiteStudentInfoWrite,
      // },
      // {
      //   roles: ['ADMIN','MANAGER'],
      //   path: '/site/studentInfo/:id/edit',
      //   component: SiteStudentInfoWrite,
      // },
      {
        pcode: 'comm_logs',
        menu: '접속로그 관리',
        name: '접속로그 관리 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/site/log',
        component: SiteLogIndex,
      },
      {
        pcode: 'comm_privacy',
        menu: '개인정보처리방침',
        name: '개인정보처리방침 - 목록',
        roles: ['ADMIN', 'MANAGER'],
        path: '/site/infoPolicy',
        component: SiteInfoPolicyIndex,
        items: [
          {
            name: '개인정보처리방침 - 상세',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/infoPolicy/:id',
            component: SiteInfoPolicyIdIndex,
          },
          {
            name: '개인정보처리방침 - 등록',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/infoPolicy/write',
            component: SiteInfoPolicyWrite,
          },
          {
            name: '개인정보처리방침 - 수정',
            roles: ['ADMIN', 'MANAGER'],
            path: '/site/infoPolicy/:id/edit',
            component: SiteInfoPolicyWrite,
          },
        ],
      },

      {
        pcode: 'comm_biz',
        menu: 'Biz. 인사이트',
        name: 'Biz. 인사이트 CP - 목록',
        roles: ['CP'],
        path: '/site/board/biz',
        component: SiteBoardBizIndex,
        items: [
          {
            name: 'Biz. 인사이트 CP - 등록',
            roles: ['CP'],
            path: '/site/board/biz/write',
            component: SiteBoardBizWrite,
          },
          {
            name: 'Biz. 인사이트 CP - 상세',
            roles: ['CP'],
            path: '/site/board/biz/:id',
            component: SiteBoardBizIdIndex,
          },
          {
            name: 'Biz. 인사이트 CP - 상세',
            roles: ['CP'],
            path: '/site/board/biz/:id/view',
            component: SiteBoardBizIdView,
          },
          {
            name: 'Biz. 인사이트 CP - 수정',
            roles: ['CP'],
            path: '/site/board/biz/:id/edit',
            component: SiteBoardBizWrite,
          },
        ],
      },
      // {
      //   roles: ['CP'],
      //   name: 'Biz. 인사이트 CP - 상세',
      //   path: '/cp/biz/:id',
      //   component: CPBizIdIndex,
      // },
    ],
  },
  {
    label: 'others',
    items: [
      {
        name: '프로필',
        roles: ['TEACHER', 'STAFF', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
        path: '/profile',
        component: ProfileIndex,
      },
      {
        name: '프로필 수정',
        roles: ['TEACHER', 'STAFF', 'STAFF', 'ADMIN', 'MANAGER', 'CP'],
        path: '/profile/edit',
        component: ProfileIdEdit,
      },
    ],
  },
];

const itemsMerge = (arr, data) => {
  const o = { ...data };

  if (!data?.items) {
    return arr.concat([data]);
  }

  const items = [...data?.items];
  delete o.items;

  return arr.concat([o, ...items]).reduce(itemsMerge, []);
};

const parseItems = (principal, group, ix) => {
  if (!group?.items) return undefined;

  let o = group.items?.filter((x) => {
    const allowMenu = !x.pcode || principal?.permissionCodes?.includes(x.pcode);
    return x?.roles?.includes(principal?.type) && allowMenu;
  });
  o = o.filter((x) => {
    const i = o.filter((x) => !!x.menu).indexOf(x);
    const items = parseItems(principal, x, [...ix, i]);
    if (!items?.length && !x.path) return false;
    return true;
  });
  return o
    .map((x) => {
      const i = o.filter((x) => !!x.menu).indexOf(x);
      const items = parseItems(principal, x, [...ix, i]);
      if (!items?.length && !x.path) return undefined;
      return { ...x, ix: [...ix, i], items };
    })
    .filter((x) => !!x);
};

export const getMenuItems = (principal, tree) => {
  const output = MENU_ITEMS.map((group, ix) => {
    return {
      label: group.label,
      items: parseItems(principal, group, [ix], tree),
    };
  }).filter((x) => x.items?.length > 0);
  if (tree) return output;

  const o = output.reduce(itemsMerge, []).filter((x) => !!x.component);
  return o;
};

export const parseHTML = (t) => {
  return t?.replace(/http:\/\//g, 'https://');
};